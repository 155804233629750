@import 'node_modules/antd/es/style/themes/default';

@height: 28px;
@padding: 6px;

.transfer-small {
  :global .@{ant-prefix}-transfer-list {
    &-header {
      height: @height;
      padding: @padding + 1;
    }
    &-body-search-wrapper {
      padding: @padding;
      .anticon {
        position: absolute;
      }
    }
    &-search {
      padding-top: 0;
      padding-bottom: 0;
    }
    &-content-item {
      padding-left: @padding;
      padding-right: @padding;
      padding-top: @padding - 2;
      padding-bottom: (@padding / 2);
      min-height: @height;
    }
    &-footer {
      height: @height;
      line-height: @height;
      text-align: center;
    }
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;