@import '~antd/es/style/themes/default';

.check-wrap {
  display: block;
}

.check-all-btn {
  line-height: 22px;
  height: 22px;
  padding: 0;
}

.category-wrap:not(:last-child) {
  margin-bottom: @margin-md;
}

.tip-overlay {
  max-width: 420px;
}

.tip-icon {
  margin-left: -3px;
  opacity: 0.3;
  transition: opacity @animation-duration-base;
  cursor: help;

  &:hover {
    opacity: 0.5;
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;