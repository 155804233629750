@import '~antd/es/style/themes/default';

@img-height: @input-height-lg;
@img-width: (110 / 40) * @img-height;

:global(.@{ant-prefix}-input-prefix) {
  padding-right: 4px;
  color: @input-border-color;
}

.verify-code {
  :global(.@{ant-prefix}-form-item-control-input-content) {
    display: flex;
  }
  &-input {
    width: calc(100% - (@img-width + @input-padding-horizontal-lg));
    margin-right: @input-padding-horizontal-lg;
  }
  &-img {
    width: @img-width;
    height: @img-height;
    // border: @border-width-base @border-style-base @input-border-color;
    border-radius: @border-radius-sm;
    cursor: pointer;
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;