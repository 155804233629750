@import '~antd/es/style/themes/default';

/**
 * 高亮文本
 */
.primary-label {
  color: @primary-color;
}
.danger-label {
  color: @error-color;
}
// .success-label {
//   color: @success-color;
// }

/**
 * 超链接文本
 * 可用于消除 Button 组件 type 为 link 时多余的边距。
 */
.link-label {
  color: @primary-color;
  padding: 0;
  transition: all @animation-duration-base;
  cursor: pointer;
  &:hover {
    color: @primary-5;
    background: @btn-link-hover-bg;
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;