@import '~antd/es/style/themes/default';

.descriptions {
  width: 600px;

  :global(.@{ant-prefix}-descriptions-header) {
    margin-bottom: @margin-sm;
  }

  :global(.@{ant-prefix}-descriptions-row) {
    & > th,
    & > td {
      padding-bottom: 3px;
    }
  }

  :global(.@{ant-prefix}-descriptions-item-label) {
    width: 145px;
    justify-content: flex-end;
  }
}

.download-button {
  margin-left: @margin-md;
  margin-right: 3px;
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;