@import '~antd/es/style/themes/default';

/**
 * 减小按钮中图标与文字的间距
 */
.@{ant-prefix}-btn > .anticon + span, .@{ant-prefix}-btn > span + .anticon {
  margin-left: 0.35em;
}

/**
 * 减小弹窗中表单的行间距
 */
 @form-help-size: 12px;
 @form-help-height: @form-help-size + 2px * 2;

.@{ant-prefix}-modal-body .@{ant-prefix}-form-item {
  margin-bottom: @form-help-height + 3px;

  &:last-child {
    margin-bottom: 0;
  }

  /** 避免因出现提示文字影响布局 */
  .@{ant-prefix}-form-item-explain, .@{ant-prefix}-form-item-extra{
    font-size: @form-help-size;
    line-height: @form-help-height;
    min-height: @form-help-height;
    margin-bottom: 0 - @form-help-height;
  }
}

/**
 * FIXED: 修复样式表优先级问题导致 labelCol={{ span: 0 }} 样式被覆盖。
 */
.ant-form-item-label.ant-col-0 {
  display: none;
}

/**
 * FIXED: 修复时间选择组件在已选时间段内重新选择时样式展示怪异问题
 */
@picker-cell-prefix-cls: ~'@{ant-prefix}-picker-cell';
@picker-hover-border: 1px dashed @picker-date-hover-range-border-color;

.@{picker-cell-prefix-cls}-in-view.@{picker-cell-prefix-cls}-in-range {
  &.@{picker-cell-prefix-cls}-range-hover {
    &::before,
    &-start::before,
    &-end::before {
      border-top: @picker-hover-border;
      border-bottom: @picker-hover-border;
    }
    &-start::before {
      border-left: @picker-hover-border;
    }
    &-end::before {
      border-right: @picker-hover-border;
    }
    &-start,
    &-end {
      .@{picker-cell-prefix-cls}-inner::after {
        border-top: @picker-hover-border;
        border-bottom: @picker-hover-border;
      }
    }
  }
}

.@{picker-cell-prefix-cls}-in-view.@{picker-cell-prefix-cls}-range-hover.@{picker-cell-prefix-cls}-selected {
  &.@{picker-cell-prefix-cls}-range-start,
  &.@{picker-cell-prefix-cls}-range-end {
    .@{picker-cell-prefix-cls}-inner {
      background: @picker-date-hover-range-border-color;
    }
  }
}

/**
 * FIXED: 修复低版本 safari 浏览器 flex 兼容问题
 */
.@{ant-prefix}-layout.@{ant-prefix}-layout-has-sider {
  width: 100%;
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;