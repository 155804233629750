@import '~antd/es/style/themes/default';

.tabs {
  margin-top: @margin-md - @padding-lg;
  margin-bottom: @margin-md - @padding-lg;

  > :global(.@{ant-prefix}-tabs-nav) {
    margin-left: @margin-md;
    margin-bottom: 0;

    :global .@{ant-prefix}-tabs-tab {
      padding: @padding-xs 0;
      &:not(.@{ant-prefix}-tabs-tab-active) a {
        color: @text-color;
      }
    }
  }

  /**
   * 已选中的菜单不允许点击
   */
  :global(.@{ant-prefix}-tabs-tab.@{ant-prefix}-tabs-tab-active) {
    // pointer-events: none;
    cursor: not-allowed;
  }
}

.tip-icon {
  margin-left: 5px;
  color: @primary-color;
  opacity: 0.75;
  transition: opacity @animation-duration-base;
  cursor: help;

  &:hover {
    opacity: 1;
  }
}

.tip-overlay {
  max-width: 420px;
  z-index: 10000;
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;