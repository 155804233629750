@import '~antd/es/style/themes/default';

@card-margin-bottom: @margin-md;
@card-padding: @padding-lg;

.layout {
  /**
   * 侧边栏固定，支持滚动
   */
  :global(.@{ant-prefix}-layout-sider) {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }

  /**
   * 已选中的菜单不允许点击
   */
  :global(.@{ant-prefix}-menu-item.@{ant-prefix}-menu-item-selected) {
    pointer-events: none;
    cursor: not-allowed;

    a {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;