@import '~antd/es/style/themes/default';

.select {
  width: 200px !important;
}

.app-wrap {
  width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: center;
}

.client-wrap {
  width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;

  :global(.@{ant-prefix}-radio-button-wrapper) {
    width: 100%;
    display: block;
    text-align: center;
  }

  :global(.@{ant-prefix}-radio-button-wrapper-checked) {
    border-top-color: #d9d9d9!important;
    border-bottom-color: #d9d9d9!important;

    &:focus-within {
      box-shadow: 0 0 0 3px transparent!important;
    }
  }
}

.platform-wrap {
  width: calc(100% - 10px);
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  white-space: nowrap; /* 防止文本换行 */
  justify-content: safe center;
  flex-wrap: wrap; /* 允许换行 */

  > div {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-weight: bold;
    padding: 0 10px; /* 设置所需的padding值 */

    &:hover {
      color: @primary-hover-color;

      svg {
        fill: @primary-hover-color;
      }
    }
  }

  > .text {
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  > .active {
    color: @primary-color;

    svg {
      fill: @primary-color;
    }
  }
}


.radioButton {
  padding: 0 2px; /* 设置所需的padding值 */
  font-size: small !important;
}

.custom-h1 {
  margin-top: 30px;
}
@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;