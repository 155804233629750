@import '~antd/es/style/themes/default';

.main {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;

  color: @white;
}
.extra {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.actions {
  display: flex;
  align-items: center;

  > .action {
    padding: 0 @padding-sm;
    cursor: pointer;
    transition: background-color @animation-duration-base;

    &:hover {
      background: rgba(@black, 0.025);
    }
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;