// @import '~antd/es/style/core/motion';

html {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

html,
body,
#root,
#app {
  height: 100%;
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;