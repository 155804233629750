@import '~antd/es/style/themes/default';

.login-page {
  min-height: 100%;
  padding: 150px 50px 50px;
  background-color: #f0f2f5;
  :global(.@{ant-prefix}-card) {
    max-width: 500px;
    margin: 0px auto;
    padding: 5px 50px 20px;
    transition: box-shadow 0.3s ease-in-out;
    z-index: 10;
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }
    :global(.@{ant-prefix}-card-head-title) {
      padding: 20px;
      text-align: center;
      font-size: 20px;
    }
  }
}

@primary-color: #7955ff;@primary-hover-color: #9d7dff;@layout-header-height: 54px;@layout-header-background: black;@menu-dark-bg: #001628;@menu-dark-inline-submenu-bg: #000e18;@rake-layout-sider-background-dark: #001628;@layout-sider-background: #001628;